/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import "./lib/i18n";
import "./src/styles/index.scss";

import { NotificationsProvider } from "@brutextiles/web-component-library";
import { default as PiwikPro } from "@piwikpro/react-piwik-pro";
// import { Authenticator } from "@containers";
// import i18n from "i18next";
import React from "react";
import { RecoilRoot } from "recoil";

/**
 * Updates the current app language to match the route data.
 */
// const updateLanguage = newLanguage => {
//     if (i18n.language !== newLanguage) {
//         i18n.changeLanguage(newLanguage);
//     }
// };

// This a workaround for the issue related to Gatsby not resolving both path and matchPath
// causing a pathName to update to /__account-id when the url have an upper case letter in the url.
// https://github.com/gatsbyjs/gatsby/issues/4337
// Bug 28181: Update hybrid solution redirect to be case insensitive
export const onInitialClientRender = () => {
    if (window.location.pathname.includes("/__account-id"))
        window.location.href = window.location.pathname.replace(
            "/__account-id",
            "",
        );
};

export const onClientEntry = () => {
    PiwikPro.initialize(
        process.env.GATSBY_PIWIKPRO_SITE_ID,
        process.env.GATSBY_PIWIKPRO_CONTAINER_URL,
    );
};

export const wrapRootElement = ({ element }) => {
    return (
        <RecoilRoot>
            <NotificationsProvider>{element}</NotificationsProvider>
        </RecoilRoot>
    );
};
