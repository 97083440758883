/**
 * DOCUMENTATION FOR i18n
 *
 * React i18n => https://react.i18next.com/
 * LanguageDetector => https://github.com/i18next/i18next-browser-languageDetector
 * HttpApi => https://github.com/i18next/i18next-http-backend#backend-options
 */

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import HttpApi from "i18next-http-backend";

// const backend = {}

const options = {
    // debug: true, // defaults to false
    lng: process.env.GATSBY_DEFAULT_LANGUAGE,
    fallbackLng: process.env.GATSBY_DEFAULT_LANGUAGE,
    load: "currentOnly", // e.g. don't load 'es' when requesting 'es-MX' -- Sitecore config should handle this
    interpolation: {
        escapeValue: false, // not needed for react
    },
    // backend,
};

export default i18n.use(LanguageDetector).use(initReactI18next).init(options);
// export default i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next).init(options);
